<template>
  <div
    class="fixed inset-0 bg-overlay flex flex-col justify-center"
    style="z-index: 1000 !important"
  >
    <transition name="slidey" appear>
      <section
        id="mod-body"
        :style="`--mxw:${mxw}`"
        class="relative w-11/12 mx-auto bg-white rounded-2xl mt-2"
      >
        <div class="absolute top-0 left-0 right-0 flex justify-end mb-1">
          <button @click="close" class="px-3 py-3 rounded-tr-2xl">
            <img
              src="@/assets/images/icons/close.svg"
              alt="icons"
              :class="`h-${bs} w-${bs}`"
            />
          </button>
        </div>

        <div id="content">
          <slot></slot>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Modal",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    mt: {
      type: [String, Number],
      default: 20,
    },
  },

  data() {
    return {
      bs: 5,
    };
  },

  computed: {
    mxw() {
      switch (this.size) {
        case "sm":
          return "30.5rem";
        case "lg":
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.bs = 7;
          return "61.75rem";
        default:
          return "39.5rem";
      }
    },
  },

  methods: {
    close() {
      this.$emit("input", false);
      if (this.$route.name == "Home") {
        this.$store.commit("general/updateForm", false);
        this.$store.commit("general/updateStep", 1);
        this.$store.commit("general/wipeInvoice");

        if (this.$route.query.st && this.$route.query.st != 1) {
          this.$router.push({
            name: "Home",
            query: { st: 1 },
          });
        }
      }
    },
  },
};
</script>

<style scoped>
#mod-body {
  max-width: var(--mxw);
  padding: 2.75rem 0 2rem;
}
#content {
  overflow-y: scroll;
  max-height: 80vh;
}

/* ---------------------------------- */
/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
} */

/* ---------------------------------- */
.slidey-leave-active,
.slidey-enter-active {
  transition: 0.4s;
}
.slidey-enter {
  transform: translate(0, 100%);
}
.slidey-leave-to {
  transform: translate(0, -100%);
}
</style>
