<template>
  <div class="font-source px-2 md:px-20 lg:px-44">
    <h4
      v-if="$route.name == 'Home'"
      class="font-semibold text-3pxl text-charlGreen text-center mb-4"
    >
      Generated Invoice
    </h4>

    <div v-if="getting" class="flex justify-center h-[50vh]">
      <spinner class="w-20 h-20" color="ansGreen" />
    </div>

    <div
      v-else-if="notFound || !invoice"
      class="w-full flex flex-col justify-center items-center h-[50vh]"
    >
      <img
        src="@/assets/images/broken_link.svg"
        alt="icon"
        class="w-20 h-20 transform rotate-180"
      />

      <p class="text-red-400 font-bold mt-2">Invoice not found</p>
    </div>

    <section v-else>
      <div id="invoice" v-html="invoice"></div>

      <div class="flex justify-center gap-4 w-full mt-10">
        <div style="width: 35%">
          <button
            :disabled="sending"
            @click="sendInvoice"
            class="flex justify-center w-full bg-ansGreen gap-4 py-2.5 rounded border border-ansGreen whither"
          >
            <img
              src="@/assets/images/email/email_send.svg"
              alt="icons"
              style="display: inline"
            />
            <span>Send via email</span>
            <spinner v-if="sending" class="" color="ansGreen" />
          </button>
        </div>

        <div style="width: 35%">
          <button
            @click="printInvoice"
            class="flex justify-center w-full gap-5 py-2.5 rounded border border-darkGreen"
          >
            <img
              src="@/assets/images/email/print.svg"
              alt="icons"
              style="display: inline"
            />
            <span>Print</span>
          </button>
        </div>

        <div v-if="$route.name == 'Home'" style="width: 35%">
          <button
            @click="closeForm"
            class="border border-gray-400 text-black w-full py-2.5 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Invoice",

  data() {
    return {
      getting: false,
      sending: false,
      notFound: true,
      invoice: null,
    };
  },

  props: {
    invoiceNumber: {
      type: [String, Number],
      required: true,
    },
  },

  async created() {
    await this.getData();
  },

  methods: {
    closeForm() {
      this.$store.commit("general/updateForm", false);
      this.$store.commit("general/wipeInvoice");
      this.$store.commit("general/updateStep", 1);
      this.$router.push({
        name: "Home",
        query: { st: 1 },
      });
    },

    async sendInvoice() {
      try {
        this.sending = true;

        const res = await this.$http.post(
          "invoice/send-invoice",
          {
            invoiceNumber: this.invoiceNumber,
            baseUrl: `${window.location.origin}/invoice`,
          },
          {
            headers: { noauth: true },
          }
        );
        this.sending = false;

        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (err) {
        console.log(err);
        this.sending = false;
      }
    },

    printInvoice() {
      const divContents = document.getElementById("invoice").innerHTML;
      const a = window.open("", "", "");
      a.document.write(
        "<html><head><title>" + document.title + "</title></head>"
      );
      a.document.write("<body>");
      a.document.write(divContents);
      a.document.write("</body></html>");
      a.document.close();
      a.focus();
      setTimeout(() => {
        a.print();
        a.close();
        return true;
      }, 700);
    },

    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.post(
          "/invoice/" + this.invoiceNumber,
          {
            baseUrl: `${window.location.origin}/invoice`,
          },
          {
            headers: { noauth: true },
          }
        );
        this.getting = false;

        if (!res) {
          return;
        }
        this.notFound = false;

        const { data } = res;
        this.invoice = data;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
@media print {
  .singleInvoice {
    page-break-after: always;
  }
}
</style>
